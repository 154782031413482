var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ProjectCard"},[_c('div',{staticClass:"top-bar",style:({
      backgroundColor: _vm.theme
    })}),_c('div',{staticClass:"logo-wrap",style:({
      backgroundColor: _vm.theme
    })}),_c('div',{staticClass:"logo",style:({
      background: `url(${_vm.info.logo}) 0 0 / cover no-repeat,` + _vm.theme
    })}),_c('el-card',{staticClass:"card",attrs:{"shadow":"always"}},[_c('div',{staticClass:"user-count"},[_c('span',{staticClass:"total"},[_vm._v(_vm._s(_vm.info.userCount || '/'))]),_vm._v("万 "),_c('div',[_vm._v("用户数")])]),_c('div',{staticClass:"project-name"},[_c('i',{staticClass:"project-icon fa",class:{
          ['fa-' + _vm.info.icon]: true
        },style:({
          color: _vm.theme
        })}),_vm._v(" "+_vm._s(_vm.info.projectName || '/')+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }