<template>
  <div class="ProjectCard">
    <div
      class="top-bar"
      :style="{
        backgroundColor: theme
      }"
    />
    <div
      class="logo-wrap"
      :style="{
        backgroundColor: theme
      }"
    />
    <div
      class="logo"
      :style="{
        background: `url(${info.logo}) 0 0 / cover no-repeat,` + theme
      }"
    />
    <el-card
      shadow="always"
      class="card"
    >
      <div class="user-count">
        <span class="total">{{ info.userCount || '/' }}</span>万
        <div>用户数</div>
      </div>
      <div class="project-name">
        <i
          class="project-icon fa"
          :style="{
            color: theme
          }"
          :class="{
            ['fa-' + info.icon]: true
          }"
        />
        {{ info.projectName || '/' }}
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    theme: {
      type: String,
      default: '#666666'
    },
    info: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less">
  .ProjectCard{
    .el-card__body{
      padding: 30px 20px 10px;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
</style>
<style scoped lang="less">
  .ProjectCard{
    width: 250px;
    height: 130px;
    margin: 10px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    &.active {
      box-shadow:0 2px 12px 0 rgba(0,0,0,.3);
    }
    .card{
      width: 100%;
      height: 100%;
      .user-count{
        font-size: 12px;
        color: #797979;
        .total{
          font-size: 28px;
          color: #000000;
          margin-right: 4px;
        }
      }
      .project-name{
        font-size: 18px;
        color: #101011;
        display: flex;
        align-items: center;
        .project-icon{
          font-size: 14px;
          margin-right: 6px;
        }
      }
    }
    .top-bar{
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 3px;
    }
    @right: 20px;
    @width: 40px;
    @height: 54px;
    .logo-wrap{
      position: absolute;
      top: 0;
      right: @right;
      width: @width;
      height: @height;
      border-radius: 0 0 @width @width;
      opacity: .6;
    }
    .logo{
      @gap: 4px;
      @radius: @width - @gap * 2;
      @top: @height - @radius - @gap;
      position: absolute;
      top: @top;
      right: @right + @gap;
      width: @radius;
      height: @radius;
      background-color: tan;
      border-radius: @radius;
    }
  }
</style>
