<template>
  <Layout>
    <div class="content">
      <ProjectCard
        v-for="(item, index) in permissionProjectList"
        :key="index"
        :theme="appIdMap[item.project].theme"
        :info="item"
        :class="{
          active: activeProject === item.project
        }"
        @click.native="handleChangeProject(item)"
      />
      <div
        v-for="item in 10"
        :key="'empty_' + item"
        class="empty"
      />
    </div>
  </Layout>
</template>

<script>
// @ is an alias to /src
import ProjectCard from '@/views/dashboard/components/ProjectCard'
import store from '@/store'
import router, { formatAsyncRoutes, resetRouter } from '@/router'
import { mapMutations, mapState, mapGetters } from 'vuex'
export default {
  name: 'Dashboard',
  components: {
    ProjectCard
  },
  data () {
    return {
      activeProject: 'home'
    }
  },
  computed: {
    ...mapState('app', ['appIdMap']),
    ...mapState('permission', ['wholePermissionList']),
    ...mapGetters('app', ['projectListForDashboard']),
    permissionProjectList () {
      const tempObjectMap = {}
      this.wholePermissionList.forEach(item => {
        if (item.startsWith('/')) {
          const poject = item.split('/')[1]
          if (!tempObjectMap[poject]) {
            tempObjectMap[poject] = true
          }
        }
      })
      return this.projectListForDashboard.filter(item => {
        if (tempObjectMap[item.project]) return true
      })
    }
  },
  mounted () {
    this.activeProject = location.pathname.split('/')[1] || 'home'
  },
  methods: {
    ...mapMutations('tagsView', ['DEL_ALL_VISITED_VIEWS']),
    async handleChangeProject (item) {
      const project = location.pathname.split('/')[1] || 'home'
      if (project === item.project) return
      resetRouter()
      const accessRoutes = await store.dispatch('permission/generateRoutes', { project: item.project })
      this.$nextTick(() => {
        router.addRoutes(formatAsyncRoutes(accessRoutes))
        this.DEL_ALL_VISITED_VIEWS()
        this.$router.push({
          name: 'Dashboard',
          target: 'blank'
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
  .content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .empty{
      width: 250px;
      height: 0;
      margin: 0 10px;
    }
  }
  .back{
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
</style>
